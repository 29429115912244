<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <ChargesTable filters="true" />
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import ChargesTable from './elements/chargesTable.vue'

export default {
  name: 'Charges',
  components: { ChargesTable }
}
</script>
