var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ACRUDTable',{ref:"crud_table",attrs:{"fields":_vm.fields || [
      //'id',
      _vm.getHelperObject('imputation'),
      'name',
      _vm.helpers.building,
      _vm.getHelperObject('building_type', 'building.building_type_id'),
      _vm.getHelperObject('property_unit', 'building.property_unit_id'),
      _vm.helpers.ownerName,
      { key: 'year', _classes: 'text-center', helper: { id: 'month_id', parser: function (month_id) { return ('20' + month_id).substring(0, 4); } } },
      _vm.helpers.monthName,
      { key: 'month_id', _classes: 'hide' },
      _vm.helpers.currencyValue
    ],"config":Object.assign({}, {url: 'admin/property_charges' + (this.building_id ? ("?conditions=building_id=" + (this.building_id)) : ''),
    route: '/admin/processes/charges',
    name: 'charge',
    lname: 'property_charges',
    //params: { _with: 'building.current_owner.contact' }, //, _lists: 'months,imputations,building_types,property_units' }, // TODO: Add Building as parameter on Backend // TODO: use whits on backend
    filters: _vm.filters
      ? [
          { key: 'month_id', multiple: true, default: 'response.data.month_ids', forced: true, list: 'months', callback: this.onGetData, config: { select_all: true } },
          { key: 'imputation', config: { select_all: true } },
          //'building_type',
          //'property_unit',
          { key: 'owner_name', use: 'owner.name' }
        ]
      : []},
    (_vm.config || {}))},on:{"preparedHelpers":_vm.onPreparedHelpers}})}
var staticRenderFns = []

export { render, staticRenderFns }