<template>
  <ACRUDTable
    ref="crud_table"
    :fields="
      fields || [
        //'id',
        getHelperObject('imputation'),
        'name',
        helpers.building,
        getHelperObject('building_type', 'building.building_type_id'),
        getHelperObject('property_unit', 'building.property_unit_id'),
        helpers.ownerName,
        { key: 'year', _classes: 'text-center', helper: { id: 'month_id', parser: month_id => ('20' + month_id).substring(0, 4) } },
        helpers.monthName,
        { key: 'month_id', _classes: 'hide' },
        helpers.currencyValue
      ]
    "
    :config="{
      url: 'admin/property_charges' + (this.building_id ? `?conditions=building_id=${this.building_id}` : ''),
      route: '/admin/processes/charges',
      name: 'charge',
      lname: 'property_charges',
      //params: { _with: 'building.current_owner.contact' }, //, _lists: 'months,imputations,building_types,property_units' }, // TODO: Add Building as parameter on Backend // TODO: use whits on backend
      filters: filters
        ? [
            { key: 'month_id', multiple: true, default: 'response.data.month_ids', forced: true, list: 'months', callback: this.onGetData, config: { select_all: true } },
            { key: 'imputation', config: { select_all: true } },
            //'building_type',
            //'property_unit',
            { key: 'owner_name', use: 'owner.name' }
          ]
        : [],
      ...(config || {})
    }"
    @preparedHelpers="onPreparedHelpers"
  />
</template>

<script>
import crudMixin from '@/app/views/_mixins/crud-mixin'

export default {
  name: 'chargesTable',
  mixins: [crudMixin],
  props: ['building_id', 'fields', 'filters', 'config', 'params'],
  methods: {
    // TODO: create a fixed method ?
    onGetData(month_ids) {
      //if (month_id) {
      //this.month = { month_id, _period_name: this.getPeriodName(month_id) }
      this.$refs.crud_table.getData(false, { month_ids }, true)
      //}
    }
  }
}
</script>
